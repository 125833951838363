import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2"
import {dashboard} from "../../Api";

const Dashboard = () => {
  /*const currentDate = new Date();
  const getMonths = () => {
    const data = [];
    for (let i = 12; i >= 0; i--) {
      data.push(moment(currentDate).add(-i, 'month').format("MMM-YY").toString())
    }
    return data
  }*/
  const [loader,setLoader] = useState(true)
  const [data,setData] = useState(null)

	useEffect(()=>{
	  if(data===null){
	    dashboard().then(res=>{
	      setData({...res.data.data})
        setLoader(false)
      })
    }
  },[data])

  return (
    <>
      <div className="row page-titles">
        <div className="col-md-5 align-self-center">
          <h4 className="text-themecolor">Dashboard</h4>
        </div>
      </div>
      {loader ?
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <h4 className="card-title">Loading...</h4>
              {/*<div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>*/}
            </div>
          </div>
        </div>
        :
        <>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total Songs</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-success"><i className="ti-music small"/> <span className="counter">{data.songs}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total Albums</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-purple"><i className="ti-book small"/> <span className="counter">{data.albums}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total Plays</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-info"><i className="ti-control-play small"/> <span className="counter">{data.plays}</span></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total users</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-success"><i className="icon-people small"/> <span className="counter">{data.users}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total Artists</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-purple"><i className="icon-user-following small"/> <span className="counter">{data.artists}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total Public Playlists</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-info"><i className="ti-world small"/> <span className="counter">{data.playlists}</span></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total Private Playlists</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-danger"><i className="ti-lock small"/> <span className="counter">{data.p_playlists}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-capitalize">Total unactive users</h5>
                <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                  <div className="ml-auto">
                    <h2 className="text-danger"><i className="icon-user-unfollow small"/> <span className="counter">{data.unactive_users}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex m-b-40 align-items-center no-block">
                  <h5 className="card-title ">User Statistics</h5>
                </div>
                <Bar
                  data={{
                    labels: [...data.months_stats],
                    datasets: [
                      {
                        label: 'New Users:',
                        datasetKeyProvider: 'Users',
                        data: data.users_stats,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(222,106,255,0.2)',
                          'rgba(67,235,215,0.2)',
                          'rgba(187,255,153,0.2)',
                          'rgba(192,132,107,0.2)',
                          'rgba(99,132,255,0.2)',
                          'rgba(97,255,21,0.2)',
                          'rgba(255,52,201,0.2)',
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(75, 192, 192, 1)',
                          'rgba(153, 102, 255, 1)',
                          'rgba(255, 159, 64, 1)',
                          'rgba(222,106,255,1)',
                          'rgba(67,235,215,1)',
                          'rgba(187,255,153,1)',
                          'rgba(192,132,107,1)',
                          'rgba(99,132,255,1)',
                          'rgba(97,255,21,1)',
                          'rgba(255,52,201,1)',
                        ],
                        borderWidth: 1
                      }]
                  }}
                  options={{
                    legend: {
                      display: false
                    },
                    scales: {
                      yAxes: [{
                        ticks: {
                          max: Math.max.apply(null,data.users_stats),
                          min: 0,
                          stepSize: Math.round(Math.max.apply(null,data.users_stats)/10)
                        }
                      }]
                    },
                    maintainAspectRatio: true
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex m-b-40 align-items-center no-block">
                  <h5 className="card-title ">Songs Statistics</h5>
                </div>
                <Bar
                  data={{
                    labels: [...data.months_stats],
                    datasets: [
                      {
                        label: 'New Users:',
                        datasetKeyProvider: 'Users',
                        data: data.songs_stats,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.2)',
                          'rgba(54, 162, 235, 0.2)',
                          'rgba(255, 206, 86, 0.2)',
                          'rgba(75, 192, 192, 0.2)',
                          'rgba(153, 102, 255, 0.2)',
                          'rgba(255, 159, 64, 0.2)',
                          'rgba(222,106,255,0.2)',
                          'rgba(67,235,215,0.2)',
                          'rgba(187,255,153,0.2)',
                          'rgba(192,132,107,0.2)',
                          'rgba(99,132,255,0.2)',
                          'rgba(97,255,21,0.2)',
                          'rgba(255,52,201,0.2)',
                        ],
                        borderColor: [
                          'rgba(255, 99, 132, 1)',
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 206, 86, 1)',
                          'rgba(75, 192, 192, 1)',
                          'rgba(153, 102, 255, 1)',
                          'rgba(255, 159, 64, 1)',
                          'rgba(222,106,255,1)',
                          'rgba(67,235,215,1)',
                          'rgba(187,255,153,1)',
                          'rgba(192,132,107,1)',
                          'rgba(99,132,255,1)',
                          'rgba(97,255,21,1)',
                          'rgba(255,52,201,1)',
                        ],
                        borderWidth: 1
                      }]
                  }}
                  options={{
                    legend: {
                      display: false
                    },
                    scales: {
                      yAxes: [{
                        ticks: {
                          max: Math.max.apply(null,data.songs_stats),
                          min: 0,
                          stepSize: Math.round(Math.max.apply(null,data.songs_stats)/10)
                        }
                      }]
                    },
                    maintainAspectRatio: true
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
}

export default Dashboard;