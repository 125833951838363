import React, {useEffect, useState} from "react";
import {trends} from "../../Api";

const Trends = () => {
  const [loader,setLoader] = useState(true)
  const [data,setData] = useState(null)

  useEffect(()=>{
    if(data===null){
      trends().then(res=>{
        setData({...res.data.data})
        setLoader(false)
      })
    }
  },[data])
  return (
    <>
      <div className="row page-titles">
        <div className="col-md-5 align-self-center">
          <h4 className="text-themecolor">Trends</h4>
        </div>
      </div>
      {loader ?
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <h4 className="card-title">Loading...</h4>
              {/*<div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>*/}
            </div>
          </div>
        </div>
        :
        <>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Top 10 trending songs of the week</h5>
                  <div className="message-box" id="msg">
                    <div className="message-widget message-scroll">
                      {data.top_song_week.map((item, i) =>
                        <section key={i}>
                          <div className="user-img">
                            <img src={item.thumbnail} alt="user" className="img-circle"/>
                          </div>
                          <div className="mail-contnet">
                            <h5>{item.title}</h5>
                            <span>{item.publisher.name} | {item.views} views</span>
                          </div>
                          <div>
                            <p>{i + 1}</p>
                          </div>
                        </section>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Top 10 trending albums of the week</h5>
                  <div className="message-box" id="msg">
                    <div className="message-widget message-scroll">
                      {data.top_album_week.map((item, i) =>
                        <section key={i}>
                          <div className="user-img">
                            <img src={item.thumbnail} alt="user" className="img-circle"/>
                          </div>
                          <div className="mail-contnet">
                            <h5>{item.title}</h5>
                            <span>{item.publisher.name}</span>
                          </div>
                          <div>
                            <p>{i + 1}</p>
                          </div>
                        </section>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Top 10 trending artists of the week</h5>
                  <div className="message-box" id="msg">
                    <div className="message-widget message-scroll">
                      {data.top_artist_week.map((item, i) =>
                        <section key={i}>
                          <div className="user-img">
                            <img src={item.avatar} alt="user" className="img-circle"/>
                          </div>
                          <div className="mail-contnet">
                            <h5>{item.name}</h5>
                          </div>
                          <div>
                            <p>{i + 1}</p>
                          </div>
                        </section>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Top 10 trending songs of all time</h5>
                  <div className="message-box" id="msg">
                    <div className="message-widget message-scroll">
                      {data.top_song.map((item, i) =>
                        <section key={i}>
                          <div className="user-img">
                            <img src={item.thumbnail} alt="user" className="img-circle"/>
                          </div>
                          <div className="mail-contnet">
                            <h5>{item.title}</h5>
                            <span>{item.publisher.name} | {item.views} views</span>
                          </div>
                          <div>
                            <p>{i + 1}</p>
                          </div>
                        </section>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Top 10 trending albums of all time</h5>
                  <div className="message-box" id="msg">
                    <div className="message-widget message-scroll">
                      {data.top_album.map((item, i) =>
                        <section key={i}>
                          <div className="user-img">
                            <img src={item.thumbnail} alt="user" className="img-circle"/>
                          </div>
                          <div className="mail-contnet">
                            <h5>{item.title}</h5>
                            <span>{item.publisher.name}</span>
                          </div>
                          <div>
                            <p>{i + 1}</p>
                          </div>
                        </section>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Top 10 trending artists of all time</h5>
                  <div className="message-box" id="msg">
                    <div className="message-widget message-scroll">
                      {data.top_artist.map((item, i) =>
                        <section key={i}>
                          <div className="user-img">
                            <img src={item.avatar} alt="user" className="img-circle"/>
                          </div>
                          <div className="mail-contnet">
                            <h5>{item.name}</h5>
                          </div>
                          <div>
                            <p>{i + 1}</p>
                          </div>
                        </section>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Trends;
