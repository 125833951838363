import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Col,
} from 'reactstrap';
// import img2 from '../../assets/images/background/login-register.jpg';
import {login} from "../../Api";
import {STATS_TOKEN, STATS_USER} from "../../redux/constants";


const img1 = '/afstream.png';
const img2 = '/login_bg.jpg';

const sidebarBackground = {
	// backgroundImage: "url(" + img2 + ")",
	backgroundImage: "linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(0, 0, 0, 0.75) 0%),url(" + img2 + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

class Login extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		status: null,
		username: '',
		password: '',
		isSubmitting: false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({isSubmitting: true})
		login(this.state.username, this.state.password)
			.then(
				res => {
					console.log(res)
					if(res.data.status === 200){
						localStorage.setItem(STATS_TOKEN,res.data.access_token)
						localStorage.setItem(STATS_USER,JSON.stringify({...res.data.data}))
						// window.location.reload()
						window.location.replace("/dashboard");
					}
					this.setState({isSubmitting: false})
					this.setState({status: res.data.error})
				}
			).catch(err=>{
			this.setState({isSubmitting: false})
			this.setState({status: 'Network error'})
		});
	}
	render() {
		return <div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box on-sidebar">
						<div className="logo">
							<span className="db"><img src={img1} width="20%" alt="logo" /></span>
							<h5 className="font-medium mb-3 text-white">Sign In to Statistics Dashboard</h5>
						</div>
						<Row>
							<Col xs="12">
								{this.state.status &&
								<div className={'alert alert-danger'}>{this.state.status}</div>
								}
								<form className="mt-3" onSubmit={(e)=>this.handleSubmit(e)}>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ti-user"></i>
											</InputGroupText>
										</InputGroupAddon>
										<input name="username" type="text" className={'form-control'} placeholder="Email or Username" required
													 onChange={(e) => this.setState({username:e.target.value})}/>
									</InputGroup>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ti-pencil"></i>
											</InputGroupText>
										</InputGroupAddon>
										<input name="password" type="password" className={'form-control'} placeholder="Password" required
													 onChange={(e) => this.setState({password:e.target.value})}/>

									</InputGroup>
									<Row className="mb-3">
										<Col xs="12">
											<button type="submit" className="btn btn-block btn-primary" disabled={this.state.isSubmitting}>Login</button>
										</Col>
									</Row>
								</form>
							</Col>
						</Row>
				</div>
			</div>
		</div>;
	}
}

export default Login;
