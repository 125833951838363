import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        Aftown music Statistics
      </footer>
    );
  }
}
export default Footer;
