import axios from 'axios';
import {STATS_TOKEN, STATS_USER} from "./redux/constants";

export const api = axios.create({
  baseURL: `https://aftownmusic.com/endpoint/stats/`,
  // baseURL: `http://localhost/aftownmusiclive/endpoint/stats/`,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

api.interceptors.response.use(
  (response) => {
    console.log('response',response)
    if(response.data.status === 400){
      // eslint-disable-next-line default-case
      switch (response.data.error){
        case 'Invalid access token':
          localStorage.removeItem(STATS_TOKEN);
          localStorage.removeItem(STATS_USER);
          window.location.replace("/auth/login");
          break;
        case 'Invalid server key':
          localStorage.removeItem(STATS_TOKEN);
          localStorage.removeItem(STATS_USER);
          window.location.replace("/auth/login");
          break;
      }
    }
    return response;
  },
  (error) => {
    // console.log('error',error.response.status)
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem(STATS_TOKEN);
      localStorage.removeItem(STATS_USER);
      window.location.replace("/auth/login");
    }
    return Promise.reject(error);
  }
);

const access_token = localStorage.getItem(STATS_TOKEN)
const user = JSON.parse(localStorage.getItem(STATS_USER))

const keys = new FormData()
keys.append('server_key','971cee2cf61395d172fdb0ef935aabff')
// keys.append('server_key','2e6fd7f7e1e5428fb8fda17649105a33')
keys.append('access_token',access_token)

export const checkStorage = () => !!!(access_token && user)

export const login = async (username, password) =>{
  keys.append('username',username)
  keys.append('password',password)
  return await api.post('login',keys).then((res) => res).catch(error => error);
}

export const dashboard = async () =>
  await api.post('dashboard',keys).then((res) => res).catch(error => error);

export const reports = async () =>
  await api.post('reports',keys).then((res) => res).catch(error => error);

export const trends = async () =>
  await api.post('trends',keys).then((res) => res).catch(error => error);
