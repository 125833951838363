import Dashboard from '../views/dashboard/dashboard';
import Trends from "../views/dashboard/trends";
import Reports from "../views/dashboard/reports";
import CustomReports from "../views/dashboard/customReports";
import ArtistRequest from "../views/dashboard/artistRequest";


var ThemeRoutes = [
	{
		navlabel: true,
		name: "Main",
		icon: "mdi mdi-dots-horizontal",
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'ti-dashboard',
		component: Dashboard
	},
	{
		path: '/trends',
		name: 'Trends',
		icon: 'ti-stats-up',
		component: Trends
	},
	{
		path: '/reports',
		name: 'Reports',
		icon: 'mdi mdi-book-multiple',
		component: Reports
	},
	/*{
		path: '/custom-reports',
		name: 'Custom Reports',
		icon: 'ti-receipt',
		component: CustomReports
	},
	{
		path: '/artist-request',
		name: 'Artist Request',
		icon: 'icon-user-follow',
		component: ArtistRequest
	},*/
	{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }
];
export default ThemeRoutes;
