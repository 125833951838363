// SETTINGS CONSTANTS
export const LOGO_BG = 'LOGO_BG';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const THEME = 'THEME';
export const DIRECTION = 'DIRECTION';
export const SIDEBAR_POSITION = 'SIDEBAR_POSITION';
export const HEADER_POSITION = 'HEADER_POSITION';
export const LAYOUT = 'LAYOUT';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';
export const STATS_USER = 'stats.user';
export const STATS_TOKEN = 'stats.token';