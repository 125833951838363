import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {checkStorage} from "../Api";


export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        if (checkStorage()) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;