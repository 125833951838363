import React, {useEffect, useState} from "react";
import {reports} from "../../Api";

const Reports = () => {
  const [loader,setLoader] = useState(true)
  const [data,setData] = useState(null)

  useEffect(()=>{
    if(data===null){
      reports().then(res=>{
        setData({...res.data.data})
        setLoader(false)
      })
    }
  },[data])
  return (
    <>
      <div className="row page-titles">
        <div className="col-md-5 align-self-center">
          <h4 className="text-themecolor">Reports</h4>
        </div>
      </div>
      {loader ?
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <h4 className="card-title">Loading...</h4>
              {/*<div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>*/}
            </div>
          </div>
        </div>
        :
        <>
          <div className="row">
            {/*<div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total New Users today</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-success"><i className="icon-user-follow"/> <span className="counter">18</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total New Users this week</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-purple"><i className="icon-user"/> <span className="counter">7469</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total New Users this month</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-danger"><i className="icon-people small"/> <span className="counter">{data.users_month}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total New Artists this month</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-purple"><i className="mdi mdi-account-star-variant small"/> <span className="counter">{data.artists_month}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total Artists this week (Mon - Sunday)</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-info"><i className="icon-user-following"/> <span className="counter">8659</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total Playlists created this week</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-success"><i className="ti-menu small"/> <span className="counter">{data.playlist_week}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total Playlists created this month</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-danger"><i className="ti-layout-list-thumb small"/> <span className="counter">{data.playlist_month}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total User Advertisements</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-info"><i className="fas fa-address-card small"/> <span className="counter">{data.user_ads}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total Active User Advertisements</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-success"><i className="mdi mdi-account-card-details small"/> <span className="counter">{data.user_ads_active}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total IOS</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-purple"><i className="ti-mobile small"/> <span className="counter">{data.ios}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total Andriod</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-danger"><i className="ti-mobile small"/> <span className="counter">{data.android}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-capitalize">Total Web ids</h5>
                  <div className="d-flex no-block align-items-center m-t-20 m-b-20">
                    <div className="ml-auto">
                      <h2 className="text-info"><i className="icon-screen-desktop small"/> <span className="counter">{data.web}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Reports;
