import React from 'react';
import indexRoutes from './routes/';
import {Router, Route, Switch, Redirect} from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { PrivateRoute } from './routes/PrivateRoutes';
import Blanklayout from './layouts/blanklayout';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }


  render() {

    return (

      <Provider store={configureStore()}>
        <Router basename="/" history={history}>
          <Switch>
            <Route path="/auth/login" component={Blanklayout} />;
            {indexRoutes.map((prop, key) => {
              return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
            })
            }
            <Redirect to="/dashboard"/>
          </Switch>
        </Router>
      </Provider>
    )
  }
}

export default App;